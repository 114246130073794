export default function () {

	$('.js-open').on('click', function() {
		$('body').toggleClass('nav-open');
	})

	$('.js-close').on('click', function() {
		$('body').toggleClass('nav-open');
	})

	$(document).keyup(function(e) {
	     if (e.keyCode == 27) {
	        $('body').removeClass('nav-open');
	    }
	});
}