export default function () {

    var $post = $('.js-post'),
        postCount = $post.length,
        caseCount = $('.js-post.etudes_de_cas').length,
        reaCount = $('.js-post.projets').length;


    $('.count.all').text('('+ postCount +')');
    $('.count.case-studies').text('('+ caseCount +')');
    $('.count.web-dev').text('('+ reaCount +')');

    $('.js-filter.all').on('click', function() {
        $post.show();
    })

    $('.js-filter').on('click', function() {
        $('.js-filter').removeClass('current');
        $(this).addClass('current');
    })

    $('.js-filter.case-studies').on('click', function () {
        $post.not('etudes_de_cas').hide();
        $('.js-post.etudes_de_cas').show();
    })

    $('.js-filter.web-development').on('click', function () {
        $post.not('projets').hide();
        $('.js-post.projets').show();
    })
};