export default function () {
        
    $('.toggle-item .title').on('click', function(e) {
        e.preventDefault();

        $(this).parent('.toggle-item').toggleClass('active');
        $(this).next('.content').slideToggle(300);
    })

}


