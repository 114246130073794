export default function () {
	var $agencySlider = $('.js-agence-slider'),
		$projectSlider = $('.js-screen-slider'),
		$globalSlider = $('.js-slider');


	// Agence slider

	$agencySlider.slick({
		slidesToShow: 1,
		slidesToScroll: 1,
		arrows: false,
		dots: false,
		slide: '.js-agence-slide',
		focusOnSelect: true,
	});



	// Projet single slider

	$projectSlider.slick({
		slidesToShow: 1,
		slidesToShow: 1,
		vertical: true,
		arrows: false,
		dots: true,
		slide: '.l-projet__screen-slide',
		appendDots: '.js-screen-dots',
		infinite: false,
		verticalSwiping: true,
	})

	//Mouse wheel slide change
	$('.l-projet.single').on('wheel', function (e) {
		e.preventDefault();

		if (e.originalEvent.deltaY < 0) {
			$('.js-slider').slick('slickPrev');
		} else {
			$('.js-slider').slick('slickNext');
		}
	});


}