export default function () {

    $(document).scroll(function() {
        var y = $(document).scrollTop();
        var $header = $('.l-header');

        if (y >= 1) {
            $header.removeClass('is-not-sticky');
            $header.addClass('is-sticky');
        } else {
            $header.removeClass('is-sticky');
            $header.addClass('is-not-sticky');
        }
    })


    var stickyOffset = $(".js-color-element").offset();
    var socialOffset = $(".js-color-element-2").offset();
    var $contentDivs = $(".js-color");

    $(document).scroll(function () {
        revealOnScroll();
    });

    $(document).ready(function () {
        revealOnScroll();
    });

    function revealOnScroll() {
        $contentDivs.each(function (k) {
            var thisOffset = $(this).offset();
            var actPosition = thisOffset.top - $(window).scrollTop();

            if (actPosition < stickyOffset.top && actPosition + $(this).height() > 0) {
                $(".js-color-element").removeClass("light dark").addClass($(this).hasClass("light") ? "light" : "dark");
                return false;
            }
        });
    }
}