export default function () {

    $('#fullpage').fullpage({
        autoScrolling: true,
        scrollHorizontally: true,
        controlArrows: false,
        slidesNavigation: false,
        loopHorizontal: false,
        slidesNavPosition: 'bottom',
        licenseKey: '58D92A78-3D4C4FAF-B3A97D07-17523DA3',
        scrollHorizontallyKey: 'YWdlbmNlY2MuY2FfbFEwYzJOeWIyeHNTRzl5YVhwdmJuUmhiR3g1dElR',
    });

    $('.js-open-project-pane').click(function (e) {
        $('body').toggleClass('project-pane-is-open');
    })

    $(document).keyup(function (e) {
        if (e.keyCode == 27) {
            $('body').removeClass('project-pane-is-open');
        }
    });

    $('body.home').on('wheel', (function (e) {
        e.preventDefault();
        if ($('body').hasClass('project-pane-is-open')) {
            if (e.originalEvent.deltaY < 0) {
                $('body').removeClass('project-pane-is-open');

                setTimeout(function() {
                    fullpage_api.setAllowScrolling(true);
                }, 300);

            }
        }
    }));

     $('.slide.franck-oliver').on('wheel', function (e) {
	 	e.preventDefault();

	 	if (e.originalEvent.deltaY < 0) {

	 	} else {
            $('body').addClass('project-pane-is-open');
            fullpage_api.setAllowScrolling(false);
	 	}
	 });

    $('.js-next-slide span:not(.js-open-project-pane)').click(function () {
        fullpage_api.moveSlideRight();

        nextSlideText();
    });

    function nextSlideText() {
        var $next = $('.js-next-slide');

        if ($('.slide.van-houtte').hasClass('active')) {
            $next.find('span').css('transform', 'translateY(0px)');
        }

        if ($('.slide.marina').hasClass('active')) {
            $next.find('span').css('transform', 'translateY(-21px)');
        }

        if ($('.slide.kuto').hasClass('active')) {
            $next.find('span').css('transform', 'translateY(-42px)');
        }

        if ($('.slide.franck-oliver').hasClass('active')) {
            $next.find('span').css('transform', 'translateY(-63px)');
        }
    }

    $(window).on('wheel', function(e) {
        if (e.originalEvent.deltaY < 0) {
            nextSlideText();
	    } else {
            nextSlideText();
	    }
    })
}
