export default function () {

    if ($('#instafeed').length) {

        var feed = new Instafeed({
            get: 'user',
            userId: '551101192',
            accessToken: '551101192.1677ed0.91a17f77ca2946238f35357ca3df8ed5',
            template: '<li style="background-image: url({{image}});"><a href="{{link}}"></a></li>',
            limit: 6,
            resolution: 'standard_resolution',
        });

        feed.run();
    }
}