export default function() {

    $('h1:not(.no-word-wrapper), h2').each(function () {
        var text = $(this).text().split(' ');

        for (var i = 0, len = text.length; i < len; i++) {
            text[i] = '<div class="word-wrapper"><span class="word">' + text[i] + '</span></div>';
        }
        $(this).html(text.join(' '))
    })


    // Footer colab
    $('.c-footer__colab').on('click', function() {

        $(this).addClass('active');
    })

    $('.wpcf7-form-control-wrap input, .wpcf7-form-control-wrap textarea').focus(function() {
		$(this).parent('.wpcf7-form-control-wrap').addClass('focused');
	})

	$('.wpcf7-form-control-wrap input, .wpcf7-form-control-wrap textarea').blur(function() {
		var $this = $(this);

		if($this.val() == '') {
			$(this).parent('.wpcf7-form-control-wrap').removeClass('focused');
		}
	})
}