export default function () {

    $('.js-open-video').click(function(e) {
        e.preventDefault();
        e.stopPropagation();

        var videoId = $('.js-vid-lb iframe').attr('data-video');

        $('.js-vid-lb iframe').attr('src', 'https://www.youtube.com/embed/'+ videoId +'?rel=0&amp;controls=0&amp;showinfo=0')

        $('.js-vid-lb').addClass('active');
    })



    // Closing the video by clicking the close button in the top right of the lightbox
    $('.js-close-lb').click(function (e) {
        e.stopPropagation();
        $('.js-vid-lb iframe').attr('src', '');
        $('.js-vid-lb').removeClass('active');

    })

    // Closing the video by clicking the body
    $('body').click(function() {
        $('.js-vid-lb iframe').attr('src', '');        
        $('.js-vid-lb').removeClass('active');
                   
    })

    // Stop the video by pressing the ESC key
    $(document).keyup(function (e) {
        if (e.keyCode == 27) {
            $('.js-vid-lb iframe').attr('src', '');
            $('.js-vid-lb').removeClass('active');
        }
    })


    // Stop propagation on wrapper so we can close the lightbox by clicking outside the video
    $('.js-vid-lb .wrapper').click(function (e) {
        e.stopPropagation();

    })
    
}