export default function () {

    $('.js-open-project-pane').click(function (e) {
        e.preventDefault();

        $('body').toggleClass('project-pane-is-open');
    })

    $(document).keyup(function (e) {
        if (e.keyCode == 27) {
            $('body').removeClass('project-pane-is-open');
        }
    });

    $('body.home').on('wheel', (function (e) {
        e.preventDefault();
        if ($('body').hasClass('project-pane-is-open')) {
            if (e.originalEvent.deltaY < 0) {
                $('body').removeClass('project-pane-is-open');
            }
        }
    }));

     $('.slide.groupe-lavoie').on('wheel', function (e) {
	 	e.preventDefault();

	 	if (e.originalEvent.deltaY < 0) {

	 	} else {
            $('body').addClass('project-pane-is-open');
	 	}
	 });
}