import './modules/helpers';
import start from './modules/router';
import scroller from './modules/scroller';
import slick from './modules/slick';
import burger from './modules/burger';
import instafeed from './modules/instafeed';
import projectPane from './modules/projectPane';
import toggle from './modules/toggle';
import sticky from './modules/sticky';
import postsFilter from './modules/postsFilter';
import reveal from './modules/reveal';
import videoLb from './modules/videoLb';
import fullpage from './modules/fullpage';
import video from './modules/video';

scroller();
slick();
burger();
instafeed();
toggle();
sticky();
postsFilter();
reveal();
videoLb();
fullpage();
video();